import $ from "jquery";

$(window).on("load", () => {

    const backgroundImageElement = $("#product-search-preview-v2-background-image");
    const wrapper_element = $("#product-search-preview-v2-grid");
    let fadingAnimation: JQuery.Promise<JQuery<HTMLElement>> = null
    let fadingType: string = null
    let next_bg: string = null

    function fadeOutAndIn(){
        fadingType = "out"
        if (backgroundImageElement.queue( "fx" ).length > 5){
            backgroundImageElement.stop(true)
        }
        fadingAnimation = backgroundImageElement.delay(50).fadeTo(300, 0, "linear").promise()
        fadingAnimation.done(() => {
            fadingType = "in"
            backgroundImageElement.attr("src", next_bg)
        }).then(() => {
            fadingAnimation = backgroundImageElement.delay(50).fadeTo(300, 1, "linear").promise()
        })
    }

    function fading(new_bg: string) {
        next_bg = new_bg
        if (fadingAnimation == null) {
            fadeOutAndIn()
        }else {
            if (fadingType === "in"){
                fadeOutAndIn()
            }
        }
    }

    if (wrapper_element) {
        wrapper_element.on("mouseleave", () => {
            if (backgroundImageElement.attr("src") !== backgroundImageElement.attr("data-default")) {
                fading(backgroundImageElement.attr("data-default"))
            }
        })
    }

    $(".product-search-preview-item-v2").each(function() {
        $(this).on("mouseenter", () => {
            fading($(this).attr("data-src"))
        })
    })
})